<template>
  <div class="failed-page page">
    <div class="padding"></div>
    <div class="modular all-modular">
      <div class="modular-title">
        本次导入成功<span class="success">{{ failedData.successNum || 0 }}</span>条，失败
        <span class="failed">{{ failedData.errorNum || 0 }}</span>条
      </div>
      <div class="modular-desc">
        请检查一下失败行数，重新创建导入文件
      </div>
      <p class="modular-tips">Tips：源文件已成功导入的内容，再次导入会造成账单重复</p>
      <div class="copy-btn"
        :data-clipboard-text="copyTxt"
        @click="copyAction">复制导入结果</div>
    </div>
    <div class="modular failed-modular"
      v-if="failedData.errorRowMap && Object.keys(failedData.errorRowMap).length">
      <div class="modular-sheet"
        v-for="(item, index) in Object.keys(failedData.errorRowMap)"
        :key="index">
        <div class="sheet-title">【{{ item }}】</div>
        <div class="failed-list"
          v-for="(list, idx) in Object.keys(failedData.errorRowMap[item])"
          :key="`failed_${idx}`">{{ errorTypeKey[list] }}：在第
          <span v-for="(value, num) in failedData.errorRowMap[item][list]"
            :key="`errornum_${num}`">
            {{ value }}
            {{ formatStr(num, failedData.errorRowMap[item][list]) }}</span>行。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RecordFailed',
  data() {
    return {
      failedData: {
        errorRowMap: {},
      },
    };
  },
  mounted() {
    document.title = '导入详情';
    this.initData();
  },
  computed: {
    errorTypeKey() {
      return {
        chargeAmountErrorList: '记账金额有误',
        chargeBillErrorList: '记账类别有误',
        chargeTimeErrorList: '记账日期有误',
        chargeTypeErrorList: '收支类型有误',
      };
    },
    copyTxt() {
      const { errorRowMap } = this.failedData;
      const { errorTypeKey } = this;
      let str = '';
      Object.keys(errorRowMap).forEach((key) => {
        str += `${key}\n`;
        Object.keys(errorRowMap[key]).forEach((list) => {
          str += errorTypeKey[list] + '：第';
          errorRowMap[key][list].forEach((value, index) => {
            str += value + this.formatStr(index, errorRowMap[key][list]);
          });
          str += '行\n';
        });
      });
      return str;
    },
  },
  methods: {
    initData() {
      let errorData = window.sessionStorage.getItem('errorData');
      if (errorData) {
        errorData = JSON.parse(errorData);
        let { errorRowMap } = errorData;
        Object.keys(errorRowMap).forEach((key) => {
          if (key) {
            Object.keys(errorRowMap[key]).forEach((idx) => {
              if (!errorRowMap[key][idx].length) {
                delete errorRowMap[key][idx];
              }
            });
          }
        });
        this.failedData = errorData;
      }
    },
    formatStr(index, data) {
      return (index + 1) < data.length ? '、' : '';
    },
    copyAction() {
      const clipboard = new window.Clipboard('.copy-btn');
      clipboard.on('success', (_) => {
        this.$toast({
          content: '复制成功',
          duration: 3000,
          className: 'copy-result-toast',
        });
      });
      clipboard.on('error', (_) => {
        console.log('浏览器不支持复制');
        this.$toast({
          content: '复制失败',
        });
        clipboard?.destroy();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.failed-page {
  min-height: 100%;
  width: 100%;
  max-width: 750px;
  margin: auto;
  font-family: PingFangSC-Medium, PingFang SC;
  background: #F6F6F6;
  border: none;
  .padding {
    padding-top: 20px;
  }
  .modular {
    background: #FFFFFF;
    border-radius: 16px;
    margin: 0 26px;
    // margin-top: 20px;
    &.all-modular {
      text-align: center;
      padding-bottom: 40px;
    }
    &-title {
      font-size: 32px;
      font-weight: 500;
      color: #333333;
      line-height: 44px;
      padding-top: 40px;
      .success {
        color: #52C41A;
      }
      .failed {
        color: #F53F3F;
      }
    }
    &-desc {
      font-size: 24px;
      font-weight: 400;
      color: #333333;
      line-height: 34px;
      margin-top: 40px;
    }
    &-tips {
      font-size: 20px;
      font-weight: 400;
      color: rgba(51,51,51,0.6);
      line-height: 28px;
      margin-top: 10px;
    }
    &.failed-modular {
      margin-top: 20px;
      padding: 20px 0;
      .modular-sheet {
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .sheet-title {
        margin: 0 38px 0 20px;
        font-size: 32px;
        font-weight: 500;
        color: #333333;
        line-height: 44px;
        margin-bottom: 20px;
      }
      .failed-list {
        margin: 0 38px 0 20px;
        margin-bottom: 20px;
        font-size: 28px;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
        > span {
          color: #F53F3F;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.copy-btn {
  width: 400px;
  height: 64px;
  border-radius: 32px;
  border: 2px solid #EF5C53;
  text-align: center;
  line-height: 64px;
  box-sizing: border-box;
  font-size: 28px;
  font-weight: 400;
  color: #EF5C53;
  margin: 40px auto 0;
}
</style>
<style>
.yy-toast.copy-result-toast {
  width: 176px;
  height: 80px;
  border-radius: 12px;
  background: rgba(0,0,0,0.6);
  border-radius: 16px;
  padding: 0;
  margin-left: -88px;
  margin-top: -40px;
  font-size: 28px;
  line-height: 80px;
}
</style>
